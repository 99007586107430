.productfeaturedetail-root {
  display: flex;
  flex-direction: column;

  &__header {
    padding: 10px 20px;
    text-align: right;
  }

  &__form {
    display: flex;
    flex-direction: column;
    align-items: center;

    &__grouped {
      display: flex;
      flex-direction: column;
    }

    &__codes {
      display: flex;
      flex-direction: column;

      & input {
        margin: 16px 0;
      }

      & button {
        padding: 20px;
      }
    }

    &__table {
      background-color: white;

      & td {
        padding: 8px;
        vertical-align: middle;
        border-bottom: 1px solid lightgray;
      }
    }
  }

  &__valuetable {
    margin-bottom: 20px;
    & thead {
      font-weight: bold;
    }
    & td {
      padding: 6px;
      border-bottom: 1px solid grey;
    }
  }
}
