.customform-root {
  color: black;
  display: flex;
  flex-direction: column;

  & label,
  input,
  select {
    margin-bottom: 10px;
  }

  & input,
  select {
    padding: 6px;
  }

  & select {
    appearance: textfield;
  }

  & button {
    margin-top: 20px;
  }

  & .form__error {
    color: red;
  }

  & .form__hidden {
    display: block;
    opacity: 0;
    height: 0px;
    margin: 0;
    padding: 0;
    visibility: hidden;
  }

  & .form__tip {
    font-size: 11px;
    color: grey;
    margin-bottom: 10px;
  }
}
